import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Collective`}</h1>
    <p>{`The Studio Collective is a loosely affiliated group of musicians and visual artists who live in or frequent San Francisco. Our goal is to foster a diverse community of producers and instrumentalists, with an emphasis on exceptional sound quality and musicianship. The collective serves as an umbrella for band performances, experimental events, collaborative studio recording, and audio-visual projects. The collective also provides performance opportunities and guidance to up-and-coming artists.`}</p>
    <h2>{`Studio Collective Improv Performances`}</h2>
    <h4>{`Instrumental Jam - Oct 2021`}</h4>
    <p>{`This past halloween, Studio Collective musicians `}<a parentName="p" {...{
        "href": "https://www.instagram.com/nicholaspaul26/"
      }}>{`Nicholas Paul`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/jamesfishmusic/"
      }}>{`James Fish`}</a>{`, and Tōsh performed an experimental, wholly improvized and instrumental jam. The hardware setup included a guitar, violin, synethsizers, drum machines, effect pedals, and a Xone 92 mixer. No music was pre-written or pre-rehearsed for the performance, and no computer or DAW was used. This performance stretched boundaries for the group, moving further away from pre-constructed and heavily refined music into a jazz-style improvization focused on a dialog amongst the musicians and with the audience.`}</p>
    <Video videoId="nln6CUzeUBg" alt="Studio Collective Jam" mdxType="Video" />
    <h4>{`Live Set with Full Band - June 2021`}</h4>
    <p>{`When COVID restrictions were lifted last June, Studio Collective musicians `}<a parentName="p" {...{
        "href": "https://www.instagram.com/nicholaspaul26/"
      }}>{`Nicholas Paul`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/tahseenrhn/"
      }}>{`Tahseen Rahman`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/mehdisiac/"
      }}>{`Medhi Aourir`}</a>{` and Tōsh performed an improvisational live set rooted in Tōsh's club set. This hybrid approach allowed a driving dance floor alongside moments of unplanned inspiration.`}</p>
    <Video videoId="Ys_jW27iVus" alt="Live in the Studio" mdxType="Video" />
    <h2>{`Songbird Music Generation Library`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/desaiashu/songbird-chirp"
      }}>{`Songbird`}</a>{` is a music generation library being developed by Tōsh. The library is built in C++ and can run on a computer sending MIDI to a DAW or on the Adafruit feather platform sending MIDI directly into hardware synthesizers. The library serves to aid live performance through both generative and responsive MIDI, enabling an interface for various synthesizers to talk to and respond to each other. It also serves to aid the composition process through algorithmic and generative composition with a foundation of music theory. Tōsh and members of the studio collective are exploring machine learning models to aid with generative music.`}</p>
    <h2>{`Electronic x Classical`}</h2>
    <p>{`The Studio Collective is working to put together an experimental show blurring the lines of electronic and classical music, inspired by acts such as Nils Frahm, Hania Rani, Sebastian Mullaert, and Henrik Schwarz. The goal is to explore a different dimension of electronic music, rooted in a fundamentally different audience experience - one that is silent and seated. The performance will juxtapose live performance on piano and synthesizers with elements and texture driven by a custom MIDI sequencer built by Tōsh. The project is being supported by a production team and visual artists. `}</p>
    <h2>{`Thursday Therapy at F8 Folsom`}</h2>
    <p>{`Thursday Therapy was a monthly dance party with house and techno music with an emphasis on community and diversity of artists. We began running the event in the pandemic as a live-stream to support one of SF's marquee venues F8 and transitioned into a monthly club night when San Francisco reopened. We sunsetted the event when F8 was back on it's feet after the pandemic. Thursday Therapy resident DJs were `}<a parentName="p" {...{
        "href": "https://www.instagram.com/cptnjay/"
      }}>{`Cptn Jay`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/_kudeki/"
      }}>{`Kudeki`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/cara_mazzi/"
      }}>{`Cara Mazzi`}</a>{`, and Tōsh.`}</p>
    <h2>{`The Issues Podcast`}</h2>
    <p>{`The Issues podcast is an interview series exploring various topics within electronic dance music featuring SF-based or affiliated musicians like `}<a parentName="p" {...{
        "href": "https://www.instagram.com/ideaunsound/"
      }}>{`Idea Unsound`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.instagram.com/jamesfishmusic/"
      }}>{`James Fish`}</a>{`. The intent is to foster dialog around the inner workings of the local and global music scene and make accessible conversations that tend to happen behind closed doors.`}</p>
    <h4>{`Issue #1: Live Coding ft. Idea Unsound`}</h4>
    <Video videoId="xRSuVyaLLPE" alt="Issue #1: Live Coding" mdxType="Video" />
    <h4>{`Issue #2: Berlin ft. James Fish`}</h4>
    <Video videoId="e6wzqqQ532M" alt="Issue #2: Berlin" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      